import { useSelector } from "react-redux";
import { AlbumIcon, DollarSignIcon, PersonIcon, ShoppingCartIcon } from "../../../../components/Svgs";
import Kpi from "../Kpi/Kpi";
import classes from "./Kpis.module.css";

const Kpis = ({ kpisData }) => {
  const { orgData } = useSelector((state) => state.orgData);
  const currecny = orgData.activities.shopSettings.currency;

  const kpis = [
    {
      name: "Net revenue",
      value: currecny + " " + kpisData?.revenueGross,
      icon: <DollarSignIcon />,
    },
    {
      name: "Albums created",
      value: kpisData?.albumsCount,
      icon: <AlbumIcon />,
    },
    {
      name: "Sales",
      value: kpisData?.salesCount,
      icon: <ShoppingCartIcon />,
    },
    {
      name: "Guests",
      value: kpisData?.guestsCount,
      icon: <PersonIcon />,
    },
  ];

  return (
    <div className={classes.kpis}>
      {kpis?.map((kpi) => (
        <Kpi kpi={kpi} key={kpi.name} />
      ))}
    </div>
  );
};

export default Kpis;

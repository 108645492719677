import classes from "./Report.module.css";
import { CloseIcon, PrintIcon } from "../../../components/Svgs";
import moment from "moment";
import AnalyticsCard from "../components/AnalyticsCard/AnalyticsCard";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Loader from "../../../components/Loader/Loader";
import Table from "../components/Table/Table";
import { getMonthlyReportData } from "../../../utils/api";
import { getOrgType } from "../../../utils/utils";

const formatCurrency = (num) => {
  return num?.toFixed(2).replace(".", ",");
};
const Report = ({ closeModalHandler, date }) => {
  const [reportData, setReportData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const { orgData } = useSelector((state) => state.orgData);
  const orgType = getOrgType(orgData?.org?.type);
  const orgId = orgData.org.orgId;
  const currecny = orgData.activities.shopSettings.currency;

  const getReportdata = useCallback(async () => {
    const reportData = await getMonthlyReportData(date, orgId, orgType);
    setReportData(reportData);
    setIsLoading(false);
  }, [orgId, date]);

  useEffect(() => {
    getReportdata();
  }, [getReportdata]);

  if (isLoading)
    return (
      <div className={classes.report} style={{ padding: 0 }}>
        <Loader />
      </div>
    );

  const analyticsData = [
    {
      title: "Albums CREATED",
      value: reportData?.numEventsCurrentMonth,
    },
    {
      title: "Orders",
      value: reportData?.numOrdersCurrentMonth,
    },
    {
      title: `NET REVENUE (${currecny})`,
      value: formatCurrency(reportData?.revenueCurrentMonth),
    },
  ];

  const billingOverview = {
    title: "Billing Overview",
    labels: ["Service / Item", "Amount", `Sum (${currecny})`],
    body: [
      ["Order fee (On-Site Sales)", reportData?.totalOrdersCash, formatCurrency(reportData?.totalFeeCash)],
      ["Order fee (Giveaway)", reportData?.totalOrdersGiveaway, formatCurrency(reportData?.totalFeeGiveaway)],
      ["Order fee (Online Shop)", reportData?.totalOrdersBank, formatCurrency(reportData?.totalFeeBank)],
    ],
    footer: (
      <>
        {(reportData?.totalOrdersCash > 0 ||
          reportData?.totalOrdersGiveaway > 0 ||
          reportData?.totalOrdersBank > 0) && (
          <tr>
            <td colSpan={2}>Total Floodin Fee</td>
            <td>{formatCurrency(reportData?.totalFee)}</td>
          </tr>
        )}
        <tr>
          <th>Online-Shop Revenue Share</th>
          <th>{reportData?.totalOrdersBank}</th>
          <td>{formatCurrency(reportData?.revenueBankShare)}</td>
        </tr>
        <tr>
          <td colSpan={2}>To be paid</td>
          <td>{formatCurrency(reportData?.payoutToOperator)}</td>
        </tr>
      </>
    ),
    note: reportData?.payoutToOperator < 0 && (
      <>
        <th>
          <p>
            <span>You will receive a payout of ZAR {formatCurrency(Math.abs(reportData?.payoutToOperator))}</span> to
            the following bank account:
            <br /> Account Name: {reportData?.accountName}, Bank: {reportData?.bankName}, Branch Code:{" "}
            {reportData?.branchCode}, Account Number: {reportData?.accountNumber}
          </p>
        </th>
        <th></th>
        <th></th>
      </>
    ),
  };

  const revenuePerTeamMember = {
    title: "Revenue per Team member",
    labels: ["Team member", "Albums created", "Orders", "Revenue (%)", "Net Revenue"],
    body: reportData?.guideLineItems.map((i, idx) => {
      let values = [];
      for (const key in i) {
        var value = i[key];
        if (key === "revenuePortion") {
          values.push(`${value}%`);
        } else if (key === "revenue") {
          values.push(formatCurrency(value));
        } else {
          values.push(value);
        }
      }
      return values;
    }),
  };

  const Orders = {
    title: "Orders",
    labels: ["Date", "Album", "Team", "Guest", "Email", "Payment", `(${currecny})`],
    body: reportData?.orders.map((i, idx) => {
      let values = [];
      for (const key in i) {
        var value = i[key];
        if (key === "date") {
          values.push(moment(i[key]).format("DD.MM."));
        } else if (key === "salesPriceGross") {
          values.push(formatCurrency(value));
        } else if (key !== "fee") {
          values.push(value);
        }
      }
      return values;
    }),
    footer: (
      <>
        <tr>
          <th colSpan={6}>Revenue</th>
          <th>{formatCurrency(reportData?.revenueCurrentMonth)}</th>
        </tr>
        <tr>
          <th colSpan={6}>Floodin Order Fees</th>
          <th>{formatCurrency(reportData?.totalFee)}</th>
        </tr>
        <tr>
          <td colSpan={6}>Profit</td>
          <td>{formatCurrency(reportData?.profit)}</td>
        </tr>
      </>
    ),
  };

  return (
    <div className={classes.report}>
      <div className={classes.container}>
        <div className={classes.header}>
          <div>
            <h4>{reportData?.operatorName}</h4>
            <h2>{moment(date).format("MMMM yy")}</h2>
          </div>
          <div className={classes.logo}>
            <img src="/blue-logo.png" alt="logo" />
          </div>

          <div className={classes.print} onClick={() => window.print()}>
            <PrintIcon />
            <span>Print / PDF</span>
          </div>
          <div className={classes["close-icon"]} onClick={closeModalHandler}>
            <CloseIcon />
          </div>
        </div>

        <div className={classes.analytics}>
          {analyticsData.map((i) => (
            <AnalyticsCard key={i.title} title={i.title} value={i.value} />
          ))}
        </div>

        <div className={classes.tables}>
          <Table data={billingOverview} />
          <Table data={revenuePerTeamMember} />
          <Table data={Orders} />
        </div>

        <footer>
          <p>Floodin Pty. Ltd., 20 Airport Road, 6600 Plettenberg Bay, South Africa</p>
        </footer>
      </div>
    </div>
  );
};

export default Report;
